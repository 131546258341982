/*
    This file is part of jellything (https://codeberg.org/metamuffin/jellything)
    which is licensed under the GNU Affero General Public License (version 3); see /COPYING.
    Copyright (C) 2024 metamuffin <metamuffin.org>
*/
/// <reference lib="dom" />
import { e } from "./jshelper/mod.ts";

globalThis.addEventListener("DOMContentLoaded", () => {
    document.getElementsByTagName("nav").item(0)?.prepend(
        e("a", e("p", "Back"), { class: ["back", "hybrid_button"], onclick() { history.back() } })
    )
})

