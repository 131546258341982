/*
    This file is part of jshelper (https://codeberg.org/metamuffin/jshelper)
    which is licensed under the GNU Affero General Public License (version 3); see /COPYING.
    Copyright (C) 2024 metamuffin <metamuffin.org>
*/

export function metric(x: number, unit = ""): string {
    if (x > 1000000000) return (x / 1000000000).toFixed(1) + "G" + unit
    if (x > 1000000) return (x / 1000000).toFixed(1) + "M" + unit
    if (x > 1000) return (x / 1000).toFixed(1) + "k" + unit
    return x.toString() + unit
}

export function duration(t: number): string {
    if (t < 0) return "-" + duration(-t)
    let h = 0, m = 0, s = 0;
    while (t > 3600) t -= 3600, h++;
    while (t > 60) t -= 60, m++;
    while (t > 1) t -= 1, s++;
    return (h ? h + "h" : "") + (m ? m + "m" : "") + (s ? s + "s" : "")
}

