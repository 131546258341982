/*
    This file is part of jshelper (https://codeberg.org/metamuffin/jshelper)
    which is licensed under the GNU Affero General Public License (version 3); see /COPYING.
    Copyright (C) 2024 metamuffin <metamuffin.org>
*/
import { e } from "./element.ts"

export class Logger<T = HTMLElement> {
    public element: HTMLElement = e("div", { class: "jsh-log" })
    constructor(
        private map_fn: (value: T) => HTMLElement = e => {
            if (e instanceof HTMLElement) return e;
            else throw new Error("logger: map_fn required if T is not HTMLElement");
        },
        private reverse = false,
        private timeout = 3000,
        private animation_duration = 1000,
    ) {

    }

    private add(v: T) {
        const el = this.map_fn(v)
        if (this.reverse) this.element.prepend(el)
        else this.element.append(el)
        return el
    }

    log(value: T) {
        const el = this.add(value)
        el.classList.add("jsh-log-line", "jsh-log-line-timeout")
        setTimeout(() => {
            el.classList.add("jsh-log-line-disappear")
            setTimeout(() => {
                this.element.removeChild(el)
            }, this.animation_duration)
        }, this.timeout + this.animation_duration)
    }
    log_persistent(value: T): () => void {
        const el = this.add(value)
        el.classList.add("jsh-log-line", "jsh-log-line-persistent")
        return () => {
            el.classList.add("jsh-log-line-disappear")
            setTimeout(() => {
                this.element.removeChild(el)
            }, this.animation_duration)
        }
    }
}
