/*
    This file is part of jshelper (https://codeberg.org/metamuffin/jshelper)
    which is licensed under the GNU Affero General Public License (version 3); see /COPYING.
    Copyright (C) 2024 metamuffin <metamuffin.org>
*/
export class OVar<T> {
    private _value: T
    private weak = false; // if weak, the source will be unsubscribed from, if all listeners are removed
    private disabled = false
    private cancel_source?: () => void
    private observers: ((v: T) => unknown)[] = []

    constructor(initial: T) {
        this._value = initial;
    }

    static interval<T>(update: () => T, interval: number): OVar<T> {
        const o = new OVar(update())
        const i = setInterval(() => o.value = update(), interval)
        o.cancel_source = () => clearInterval(i)
        o.weak = true;
        return o
    }

    get value() { return this._value }
    set value(v: T) { this._value = v; this.change() }
    change() { this.observers.forEach(o => o(this._value)) }

    onchange(handler: (v: T) => unknown): () => void {
        if (this.disabled) throw new Error("obervable is disabled");
        this.observers.push(handler)
        if (this.observers.length > 16) console.warn("likely memory leak here:", this);
        return () => {
            this.observers = this.observers.filter(o => o != handler)
            if (this.observers.length == 0 && this.weak) {
                this.cancel_source!()
                this.disabled = true
            }
        }
    }
    onchangeinit(handler: (v: T) => unknown): () => void {
        const abort = this.onchange(handler)
        handler(this.value)
        return abort
    }
    map<U>(fn: (v: T) => U): OVar<U> {
        const uv = new OVar(fn(this.value))
        uv.cancel_source = this.onchange(v => uv.value = fn(v))
        uv.weak = true
        return uv;
    }
    liftA2<U, V>(other: OVar<U>, fn: (x: T, y: U) => V): OVar<V> {
        const uv = new OVar(fn(this.value, other.value))
        const c1 = this.onchange(x => uv.value = fn(x, other.value))
        const c2 = other.onchange(y => uv.value = fn(this.value, y))
        uv.cancel_source = () => { c1(); c2() }
        uv.weak = true
        return uv;
    }
    wait_for(val: T) {
        return new Promise<void>(r => {
            const abort = this.onchangeinit(v => {
                if (v == val) {
                    r()
                    abort()
                }
            })
        })
    }
}
