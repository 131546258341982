/*
    This file is part of jellything (https://codeberg.org/metamuffin/jellything)
    which is licensed under the GNU Affero General Public License (version 3); see /COPYING.
    Copyright (C) 2024 metamuffin <metamuffin.org>
*/
/// <reference lib="dom" />

export class Popup {
    trigger_hov = false
    content_hov = false
    content?: HTMLElement
    shown = false

    constructor(
        public trigger: HTMLElement,
        public container: HTMLElement,
        public new_content: () => HTMLElement
    ) {
        trigger.onmouseenter = () => {
            this.trigger_hov = true;
            this.update_hov()
        }
        trigger.onmouseleave = () => {
            this.trigger_hov = false;
            this.update_hov()
        }
    }

    set_shown(s: boolean) {
        if (this.shown == s) return
        if (s) {
            this.content = this.new_content()
            this.content.addEventListener("mouseenter", () => {
                this.content_hov = true;
                this.update_hov()
            })
            this.content.addEventListener("mouseleave", () => {
                this.content_hov = false;
                this.update_hov()
            })
            this.content.classList.add("jsp-popup")
            this.container.append(this.content)
        } else {
            const content = this.content!
            content.classList.add("jsp-popup-out")
            setTimeout(() => {
                //@ts-ignore i know
                const child_undo: undefined | (() => void) = content["jsh_undo"]
                if (child_undo) child_undo()
                this.container.removeChild(content)
            }, 100)
            this.content = undefined
        }
        this.shown = s
    }

    hide_timeout?: number
    update_hov() {
        if (this.content_hov || this.trigger_hov) {
            this.set_shown(true)
            if (this.hide_timeout !== undefined) {
                clearTimeout(this.hide_timeout)
                this.hide_timeout = undefined
            }
        } else {
            if (this.hide_timeout === undefined) this.hide_timeout = setTimeout(() => {
                this.set_shown(false)
                this.hide_timeout = undefined
            }, 100)
        }
    }
}
